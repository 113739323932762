import revive_payload_client_n4u09LibZH from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_2crn7ffs4bqt6olwocp2lc57fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ma7ntjFZdT from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_2crn7ffs4bqt6olwocp2lc57fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Q45FqZWsVz from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_2crn7ffs4bqt6olwocp2lc57fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_3iU67fDn87 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_2crn7ffs4bqt6olwocp2lc57fe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_gGYAcQnDxm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_2crn7ffs4bqt6olwocp2lc57fe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_S56RU43dxx from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_2crn7ffs4bqt6olwocp2lc57fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_m3IESyGGMU from "/vercel/path0/node_modules/.pnpm/nuxt-viewport@2.1.5_rollup@4.18.0_vue@3.4.27_typescript@5.4.5_/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import slideovers_pwlFMu3Chw from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_74ajea42lu6sxn35hppoh62ebu/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_9nX872uc9b from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_74ajea42lu6sxn35hppoh62ebu/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_8DuUESzuyJ from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_74ajea42lu6sxn35hppoh62ebu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_FebLHoYF3B from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_sbSFxh9ARl from "/vercel/path0/node_modules/.pnpm/nuxt-directus@5.6.1_rollup@4.18.0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import chunk_reload_client_DrdqKboqwH from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_2crn7ffs4bqt6olwocp2lc57fe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ofetch_azZBrUYZIy from "/vercel/path0/plugins/ofetch.js";
import vue_mountable_fz1MuxJRc2 from "/vercel/path0/plugins/vue-mountable.js";
export default [
  revive_payload_client_n4u09LibZH,
  unhead_ma7ntjFZdT,
  router_Q45FqZWsVz,
  payload_client_3iU67fDn87,
  check_outdated_build_client_gGYAcQnDxm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_S56RU43dxx,
  plugin_client_m3IESyGGMU,
  slideovers_pwlFMu3Chw,
  modals_9nX872uc9b,
  colors_8DuUESzuyJ,
  plugin_client_FebLHoYF3B,
  plugin_sbSFxh9ARl,
  chunk_reload_client_DrdqKboqwH,
  ofetch_azZBrUYZIy,
  vue_mountable_fz1MuxJRc2
]