import { default as _91id_93hv391SITD7Meta } from "/vercel/path0/pages/courses/[id].vue?macro=true";
import { default as indexkaXcMWGIjPMeta } from "/vercel/path0/pages/courses/index.vue?macro=true";
import { default as homeaAKJ71q3qIMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as lokulprWe6ymMeta } from "/vercel/path0/pages/lo.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91id_9347ZHsizslMMeta } from "/vercel/path0/pages/modules/[id].vue?macro=true";
import { default as indexJNHKLRIx7GMeta } from "/vercel/path0/pages/modules/index.vue?macro=true";
import { default as _91_91id_93_93I4I7ICTVuSMeta } from "/vercel/path0/pages/playlist/[[id]].vue?macro=true";
import { default as pS7Wz5Wg0opMeta } from "/vercel/path0/pages/playlist/p.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as sigCC95YNlRQlMeta } from "/vercel/path0/pages/sig.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as _91id_93V6KFOTkZHJMeta } from "/vercel/path0/pages/videos/[id].vue?macro=true";
import { default as indexyhVFmGhtiiMeta } from "/vercel/path0/pages/videos/index.vue?macro=true";
export default [
  {
    name: _91id_93hv391SITD7Meta?.name ?? "courses-id",
    path: _91id_93hv391SITD7Meta?.path ?? "/courses/:id()",
    meta: _91id_93hv391SITD7Meta || {},
    alias: _91id_93hv391SITD7Meta?.alias || [],
    redirect: _91id_93hv391SITD7Meta?.redirect,
    component: () => import("/vercel/path0/pages/courses/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkaXcMWGIjPMeta?.name ?? "courses",
    path: indexkaXcMWGIjPMeta?.path ?? "/courses",
    meta: indexkaXcMWGIjPMeta || {},
    alias: indexkaXcMWGIjPMeta?.alias || [],
    redirect: indexkaXcMWGIjPMeta?.redirect,
    component: () => import("/vercel/path0/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: homeaAKJ71q3qIMeta?.name ?? "home",
    path: homeaAKJ71q3qIMeta?.path ?? "/home",
    meta: homeaAKJ71q3qIMeta || {},
    alias: homeaAKJ71q3qIMeta?.alias || [],
    redirect: homeaAKJ71q3qIMeta?.redirect,
    component: () => import("/vercel/path0/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lokulprWe6ymMeta?.name ?? "lo",
    path: lokulprWe6ymMeta?.path ?? "/lo",
    meta: lokulprWe6ymMeta || {},
    alias: lokulprWe6ymMeta?.alias || [],
    redirect: lokulprWe6ymMeta?.redirect,
    component: () => import("/vercel/path0/pages/lo.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_9347ZHsizslMMeta?.name ?? "modules-id",
    path: _91id_9347ZHsizslMMeta?.path ?? "/modules/:id()",
    meta: _91id_9347ZHsizslMMeta || {},
    alias: _91id_9347ZHsizslMMeta?.alias || [],
    redirect: _91id_9347ZHsizslMMeta?.redirect,
    component: () => import("/vercel/path0/pages/modules/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJNHKLRIx7GMeta?.name ?? "modules",
    path: indexJNHKLRIx7GMeta?.path ?? "/modules",
    meta: indexJNHKLRIx7GMeta || {},
    alias: indexJNHKLRIx7GMeta?.alias || [],
    redirect: indexJNHKLRIx7GMeta?.redirect,
    component: () => import("/vercel/path0/pages/modules/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93I4I7ICTVuSMeta?.name ?? "playlist-id",
    path: _91_91id_93_93I4I7ICTVuSMeta?.path ?? "/playlist/:id?",
    meta: _91_91id_93_93I4I7ICTVuSMeta || {},
    alias: _91_91id_93_93I4I7ICTVuSMeta?.alias || [],
    redirect: _91_91id_93_93I4I7ICTVuSMeta?.redirect,
    component: () => import("/vercel/path0/pages/playlist/[[id]].vue").then(m => m.default || m)
  },
  {
    name: pS7Wz5Wg0opMeta?.name ?? "playlist-p",
    path: pS7Wz5Wg0opMeta?.path ?? "/playlist/p",
    meta: pS7Wz5Wg0opMeta || {},
    alias: pS7Wz5Wg0opMeta?.alias || [],
    redirect: pS7Wz5Wg0opMeta?.redirect,
    component: () => import("/vercel/path0/pages/playlist/p.vue").then(m => m.default || m)
  },
  {
    name: settingsyZZu4Rd1aAMeta?.name ?? "settings",
    path: settingsyZZu4Rd1aAMeta?.path ?? "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    alias: settingsyZZu4Rd1aAMeta?.alias || [],
    redirect: settingsyZZu4Rd1aAMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: sigCC95YNlRQlMeta?.name ?? "sig",
    path: sigCC95YNlRQlMeta?.path ?? "/sig",
    meta: sigCC95YNlRQlMeta || {},
    alias: sigCC95YNlRQlMeta?.alias || [],
    redirect: sigCC95YNlRQlMeta?.redirect,
    component: () => import("/vercel/path0/pages/sig.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93V6KFOTkZHJMeta?.name ?? "videos-id",
    path: _91id_93V6KFOTkZHJMeta?.path ?? "/videos/:id()",
    meta: _91id_93V6KFOTkZHJMeta || {},
    alias: _91id_93V6KFOTkZHJMeta?.alias || [],
    redirect: _91id_93V6KFOTkZHJMeta?.redirect,
    component: () => import("/vercel/path0/pages/videos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyhVFmGhtiiMeta?.name ?? "videos",
    path: indexyhVFmGhtiiMeta?.path ?? "/videos",
    meta: indexyhVFmGhtiiMeta || {},
    alias: indexyhVFmGhtiiMeta?.alias || [],
    redirect: indexyhVFmGhtiiMeta?.redirect,
    component: () => import("/vercel/path0/pages/videos/index.vue").then(m => m.default || m)
  }
]