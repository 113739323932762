import { ofetch } from "ofetch";

export default defineNuxtPlugin((_nuxtApp) => {
  globalThis.$fetch = globalThis.$fetch.create({
    async onRequest({ _request, options }) {
      // console.log("bonjour je mapelle raponse");
      const { refreshTokens, checkAutoRefresh, expires, token } = useDirectusToken();

      const token_expires_in = computed(() => Math.max(0, (expires.value ?? 0) - new Date().getTime()))
      const token_expired = computed(() => !token.value || token_expires_in.value === 0)

      // console.log(token_expired.value, token_expires_in.value);

    //   await checkAutoRefresh();
    },
    async onResponseError({ response }) {
      console.log("error code", response.status, response);
      //   if (response.status === 401) {
      //     const tokenExpiredError = response?._data?.errors?.find(
      //       (error) => error.extensions.code === "TOKEN_EXPIRED"
      //     );

      //     if (tokenExpiredError) {
      //       console.log("TOKEN EXPIRED", tokenExpiredError);
      //       const { refreshTokens, checkAutoRefresh } = useDirectusToken();
      //       await checkAutoRefresh();
      //     }
      //   }
      // console.log("&&&&&&&&&&&&&&&&&&", a);
      console.error("ON A CATCH l'ERREUR POTOOOO", request);
    },
  });
  // console.log("OK ON A SETUP LE BAIL");
});
