<script setup>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
// import videojs from "video.js";
// import offsetPlugin from "videojs-offset";
// window.videojs = videojs;
// window.videojs.registerPlugin("offset", offsetPlugin);

dayjs.extend(duration);
//
useHead({
  title: "Jbb",
  meta: [
    {
      name: "description",
      content: "Jbb",
    },
  ],
});
</script>

<template>
  <div class="">
    <!-- <NuxtLoadingIndicator/> -->
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <UNotifications />
  </div>
</template>

<style>
body,
#__nuxt,
html {
  height: 100%;
  font-family: Roboto, sans-serif;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
</style>
